import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { DatePipe } from "@angular/common";
import { PedidoServico } from "../../servicos/pedido/pedido.servico";
import { ClienteServico } from "../../servicos/cliente/cliente.servico";
import { Cliente } from "../../model/cliente";
import { Pedido, StatusEnum, OrigemPedidoEnum, FormaPagamentoEnum } from "../../model/pedido";
import { List } from 'linqts';
import { fraction, format } from "mathjs";
import { Entregador, ContratoEnum } from "../../model/entregador";
import { EntregadorServico } from "../../servicos/entregador/entregador.servico";
import { ProdutoServico } from "../../servicos/produto/produto.servico";
import { Telefone } from "../../model/telefone";
import { MotivoCancelamento } from "../../model/motivoCancelamento";
import { RegistroPedidoIfood } from "../../model/registroPedidoIfood";
import { ConfiguracaoSistemaServico } from "../../servicos/configuracaoSistema/configuracaoSistema.servico";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { UsuarioServico } from "../../servicos/usuario/usuario.servico";
import { } from 'googlemaps';
import { Usuario } from "../../model/usuario";

//variavel para utilizar jquery
declare var $: any;

@Component({
  selector: "app-fluxo-entregas-consulta",
  templateUrl: "./consulta.component.html",
  styleUrls: ["./consulta.component.css"]
})

export class EntregasConsultaComponent implements OnInit {
  public txtConsultaPedido: string = "";
  private data: Date = new Date();
  //public txtPlaceholderConsulta: string = `Digite um pedido ou um telefone. Ex.: ${parseInt(this.datePipe.transform(new Date(), "yyyyMMdd", "-0300"))}... 55111264`;
  public txtPlaceholderConsulta: string = `Ex.: 307, 55111264 ou João`;
  public ehTelefone: boolean = false;
  public ativar_spinner: boolean = false;
  public mensagem: string;
  public cliente: Cliente;
  public listaPedidos: Pedido[];
  public StatusEnum = StatusEnum;
  public ContratoEnum = ContratoEnum;
  public OrigemPedidoEnum = OrigemPedidoEnum;
  public pedidoSelecionado: Pedido = new Pedido();
  public entregadorSelecionado: Entregador = new Entregador();
  public entregadorSelecionadoId: number;
  public listaEntregadoresAtivos: Entregador[] = [];
  public partesPizza: number = 0;
  public consultaEspecifica: boolean = false;
  public filtroHoje: boolean = true;
  public filtroAbertos: boolean = false;
  public filtroIfood: boolean = false;
  public motivoCancelamento: MotivoCancelamento = new MotivoCancelamento();
  public formaPagamento: string = "0";
  public trocoPara: number = 0;
  public valorPedido: number = 0;
  public statusLojaIfood: string = "";
  public statusLojaIfoodCss: string = "";
  public statusLojaIfoodMsg: string = "";
  public permiteIntegracaoIfood: boolean = false;
  public permiteCancelarPedido: boolean = true;
  pag: Number = 1;
  contador: Number = 30;
  public ascNumberSort = false;
  public distanciaRoteiro: string;
  public urlRoteiro: string;
  public listaPedidosRoteiro: Pedido[] = [];
  public listaEnderecosRoteiro = [];
  @ViewChild('map') mapElement: any;
  @ViewChild('painel') painelElement: any;
  public directionsService: any;
  public directionsRenderer: any;
  map: google.maps.Map;
  loja: any;
  gapi: any;
  public qrCodeGerado: any = false;
  public temEnderecos: any = false;
  public permiteGerarRoteiro: boolean = false;
  public codigoSistema: number = 0;
  public indicePedidoSelecionado: number = 0;
  public classeListaPedidos: string = "container";


  constructor(private datePipe: DatePipe, private pedidoServico: PedidoServico, private clienteServico: ClienteServico, private entregadorServico: EntregadorServico, private configuracaoSistemaServico: ConfiguracaoSistemaServico, private router: Router, private usuarioServico: UsuarioServico, private http: HttpClient) {
    if (this.usuarioServico.usuario_autenticado()) {
      this.preencheListaEntregadores();
    }
    else {
      this.router.navigate(['/login']);
    }
  }

  ngOnInit(): void {
    $(() => {
      $(document).ready(function () {
        $('#txtConsulta').focus();
      })
    });
    this.listaComandasAbertas();
    this.updateTooltip();
    var self = this;

    this.configuracaoSistemaServico.obterTodos()
      .subscribe(
        data => {
          if (data != null && data.length > 0) {
            this.loja = { lat: -0.0, lng: -0.0 };
            data.forEach(config => {

              if (config.descricao == 'PermiteIntegracaoIfood' && config.valor == 'true') {
                this.obterStatusLojaIfood();
                this.permiteIntegracaoIfood = true;
              }
              else if (config.descricao == 'QtdePedidosGrid') {
                this.contador = parseInt(config.valor);
              }
              else if (config.descricao == 'PermiteCancelarPedido' && config.valor == 'false') {
                this.permiteCancelarPedido = false;
              }
              else if (config.descricao == 'CodigoSistema') {
                this.codigoSistema = Number(config.valor);
              }

              if (config.descricao == 'PosicaoLatitudeLoja') {
                this.loja.lat = Number(config.valor);
              }
              else if (config.descricao == 'PosicaoLongitudeLoja') {
                this.loja.lng = Number(config.valor);
              }

              if (config.descricao == 'PermiteGerarRoteiroTelaPrincipal')
                this.permiteGerarRoteiro = config.valor == 'true';

            });

            const mapProperties = {
              center: this.loja,
              zoom: 16,
              mapTypeId: google.maps.MapTypeId.TERRAIN,
              clickableIcons: false
            };
            this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);

            //como resolver o Marker descontinuado
            //https://developers.google.com/maps/documentation/javascript/load-maps-js-api

            new google.maps.Marker({
              animation: google.maps.Animation.DROP,
              position: this.loja,
              map: this.map
            });

            this.directionsService = new google.maps.DirectionsService();
            this.directionsRenderer = new google.maps.DirectionsRenderer({
              draggable: true,
              map: this.map,
              panel: this.painelElement.nativeElement,
            });


            this.directionsRenderer.addListener("directions_changed", () => {
              const directions = self.directionsRenderer.getDirections();
              if (directions) {
                self.computeTotalDistance(directions);
              } else {
                self.distanciaRoteiro = 0 + " km";
              }
            });



          }
        },
        err => {
          console.log(err.error);
        }
      );
  }

  adicionaRemoveRoteiro(pedido: Pedido) {



    if (this.listaPedidosRoteiro.filter(p => p.id == pedido.id).length == 0) {
      this.listaPedidosRoteiro.push(pedido);
    }
    else {
      this.listaPedidosRoteiro = this.listaPedidosRoteiro.filter(p => p.id != pedido.id);
    }

    this.displayRoute();


    this.qrCodeGerado = false;
  }

  displayRoute() {
    var origin = this.loja;
    var destination = this.loja;
    var service = this.directionsService;
    var display = this.directionsRenderer;

    if (this.listaPedidosRoteiro.length > 0) {
      this.listaEnderecosRoteiro = this.listaPedidosRoteiro.map(function (pedidoSelecionado) {
        var end = pedidoSelecionado.cliente.enderecos[0];
        return { location: `${end.logradouro}, ${end.numero} - ${end.bairro} - ${end.cidade}, ${end.uf}`, stopover: true };
      });

    } else {
      this.listaEnderecosRoteiro = [];
    }

    this.temEnderecos = this.listaPedidosRoteiro.length > 0;

    service
      .route({
        origin: origin,
        destination: destination,
        waypoints: this.listaEnderecosRoteiro,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING
      })
      .then((result) => {
        display.setDirections(result);
      })
      .catch((e) => {
        alert("Não foi possível mostrar a direção para: " + e);
      });

  }

  computeTotalDistance(result) {
    let total = 0;
    const myroute = result.routes[0];

    if (!myroute) {
      this.distanciaRoteiro = total + " km";
      return;
    }

    let ends = [];

    for (let i = 0; i < myroute.legs.length; i++) {
      total += myroute.legs[i].distance.value;

      if (ends.indexOf(encodeURI(myroute.legs[i].start_address)) < 0 && i > 1)
        ends.push(encodeURI(myroute.legs[i].start_address));

      if (ends.indexOf(encodeURI(myroute.legs[i].end_address)) < 0 && i < myroute.legs.length - 1)
        ends.push(encodeURI(myroute.legs[i].end_address));
    }

    var waypoint_place_ids = result.geocoded_waypoints.map(function (w) { return w.place_id })
    var waypoints = ends;

    total = total / 1000;
    this.distanciaRoteiro = total + " km";
    this.urlRoteiro = `https://www.google.com/maps/dir/?api=1&travelmode=driving&origin=${this.loja.lat},${this.loja.lng}&destination=${this.loja.lat},${this.loja.lng}&waypoints=${waypoints.join('%7C')}`;
  }

  gerarQrCode() {
    this.configuracaoSistemaServico.shortlink(this.urlRoteiro).subscribe(data => {
      this.urlRoteiro = data.url;
      this.qrCodeGerado = true;
    });

  }

  validaTelefone(event) {
    //this.ehTelefone = !new RegExp(/([2][0-1][0-5][0-9])(([1][0-2]|[0][1-9]))([0][1-9]|[1-2][0-9]|[3][0-1])/).test(this.txtConsultaPedido || '');

    if (parseFloat(this.txtConsultaPedido) > 9999) {
      this.ehTelefone = true;
    }
    else {
      this.ehTelefone = false;
    }

    if (event.keyCode == 13) {
      this.consultaPedidos();
    }
  }

  public updateTooltip() {

    //jquery
    $(() => {
      $('[data-toggle=tooltip]').tooltip('dispose');
      $('[data-toggle=tooltip]').tooltip({ container: 'body' });
    });

  }

  updateSelecionaTodos() {
    $(() => {
      $('.selecionaTodos').click(function () {
        var marcado = $(this).prop('checked');
        $('.selecionaItem').prop('checked', marcado);
      });
    });
  }

  consultaPedidos() {
    this.ativar_spinner = true;
    this.consultaEspecifica = true;
    var self = this;
    if (this.ehTelefone) {
      var telefone = new Telefone();
      telefone.numero = parseFloat(this.txtConsultaPedido);
      this.pedidoServico.buscaPedidosPorTelefone(telefone, self.filtroHoje).subscribe(
        pedidos => {
          self.listaPedidos = pedidos;
          self.mensagem = null;
          self.ativar_spinner = false;
          self.updateTooltip();
          self.updateSelecionaTodos();
        },
        erro => {
          self.mensagem = erro.error;
          self.ativar_spinner = false;
        }
      );
    } else {
      if (parseFloat(this.txtConsultaPedido) > 0) {
        this.pedidoServico.consultaPedidoPorNumero(parseFloat(this.txtConsultaPedido)).subscribe(
          pedido => {
            var listaPedidos = new List<Pedido>([pedido]);
            self.listaPedidos = listaPedidos.ToArray();
            self.mensagem = null;
            self.ativar_spinner = false;
            self.updateTooltip();
            self.updateSelecionaTodos();
          },
          erro => {
            self.mensagem = erro.error;
            self.ativar_spinner = false;
          }
        );
      }
      else {
        var cliente = new Cliente();
        cliente.nome = this.txtConsultaPedido;

        this.pedidoServico.consultaPedidosPorNome(cliente, self.filtroHoje).subscribe(
          pedidos => {
            self.listaPedidos = pedidos;
            self.mensagem = null;
            self.ativar_spinner = false;
            self.updateTooltip();
            self.updateSelecionaTodos();
          },
          erro => {
            self.mensagem = erro.error;
            self.ativar_spinner = false;
          }
        );
      }
    }


  }


  consultarDetalhesPedido(pedido: Pedido, indicePedido: number) {
    var self = this;

    this.pedidoSelecionado = pedido;
    this.indicePedidoSelecionado = indicePedido;

    $(() => {
      $('#modalConsulta').modal('show');
      $('#modalConsulta')
        .off('shown.bs.modal')
        .on('shown.bs.modal', function () {
          $('#fecharModalConsultaPedido').focus();
        });

      $('#modalConsulta')
        .off('hidden.bs.modal')
        .on('hidden.bs.modal', function () {
          self.pedidoSelecionado = new Pedido();
        });
    });
  }

  public obtemFracao(n: number) {
    return format(fraction(n));
  }

  public somaPartes(parte: number): boolean {
    this.partesPizza += parte;
    if (this.partesPizza >= 0.99) {
      this.partesPizza = 0;
      return true;
    }
    return false;
  }


  preencheListaEntregadores() {
    var self = this;

    this.entregadorServico.obterTodosEntregadores().subscribe(
      entregadores => {
        self.listaEntregadoresAtivos = new List<Entregador>(entregadores).Where(e => e.ativo == true).ToArray();
        self.listaEntregadoresAtivos.map(e => {
          e.search_label = `${e.nome} ${e.apelido} ${e.telefone} ${e.contrato}`;
          return e;
        });

      },
      erro => { self.mensagem = erro.error; }
    );
  }



  definirEntregador(pedido: Pedido) {
    var self = this;
    this.pedidoSelecionado = pedido;

    $(() => {
      $('#modalDefinirEntregador').modal('show');

      $('#modalDefinirEntregador')
        .off('shown.bs.modal')
        .on('shown.bs.modal', function () {
          $('#lstEntregadores').find('input').focus();
        });


      $('#modalDefinirEntregador')
        .off('hidden.bs.modal')
        .on('hidden.bs.modal', function () {
          self.pedidoSelecionado = new Pedido();
          self.entregadorSelecionadoId = 0;
        });
    });

  }


  customSearchFn(term: string, item: any) {

    term = term.toLowerCase();

    // Creating and array of space saperated term and removinf the empty values using filter
    let splitTerm = term.split(' ').filter(t => t);

    let isWordThere = [];

    var ehNumero = !isNaN(Number(term));

    // Pushing True/False if match is found
    splitTerm.forEach(arr_term => {
      if (ehNumero) {
        let search = item['codigo'];
        isWordThere.push(search == Number(arr_term));
      }
      else {
        let search = item['search_label'].toLowerCase();
        isWordThere.push(search.indexOf(arr_term) != -1);
      }

    });

    const all_words = (this_word) => this_word;
    // Every method will return true if all values are true in isWordThere.
    return isWordThere.every(all_words);
  }


  atribuirEntregador() {
    var self = this;
    self.ativar_spinner = true;
    this.pedidoSelecionado.entregadorId = this.entregadorSelecionadoId;
    this.pedidoSelecionado.entregador = null;
    //this.pedidoSelecionado.cliente = null;
    this.pedidoSelecionado.status = StatusEnum.SaiuParaEntrega;
    this.pedidoSelecionado.dataDespacho = new Date();

    // chamar servico para atribuir entregador ao pedido
    this.pedidoServico.atualizar(this.pedidoSelecionado).subscribe(
      pedidoRetorno => {
        self.mensagem = `O pedido ${pedidoRetorno.numeroPedidoDia} será entregue por ${pedidoRetorno.entregador.nome}(${pedidoRetorno.entregador.apelido})`;

        //self.atualizarStatusIfood(pedidoRetorno, null);

        self.ativar_spinner = false;
        $(() => {
          $('#modalDefinirEntregador').modal('hide');
          if (self.consultaEspecifica)
            self.consultaPedidos();
          else
            self.listaComandasAbertas();

          window.setTimeout(function () { self.mensagem = "" }, 5000);
        })
      },
      erro => {
        self.mensagem = erro.error;
        self.ativar_spinner = false;
      }

    )

  }

  atribuirEntregadorRoteiro() {
    var self = this;
    self.ativar_spinner = true;
    var msg = "O(s) pedido(s) ";
    var pedidosRetorno = [];
    var entregador: Entregador;
    this.listaPedidosRoteiro.forEach(async pedidoSelecionado => {

      pedidoSelecionado.entregadorId = this.entregadorSelecionadoId;
      pedidoSelecionado.entregador = null;
      pedidoSelecionado.status = StatusEnum.SaiuParaEntrega;
      pedidoSelecionado.dataDespacho = new Date();

      window.setTimeout(function () {

        self.pedidoServico.atualizar(pedidoSelecionado).subscribe(
          pedidoretorno => {
            //pedidosretorno.push(pedidoretorno.numeropedidodia);// = `${pedidoretorno.numeropedidodia} será entregue por ${pedidoretorno.entregador.nome}(${pedidoretorno.entregador.apelido})`;
            //entregador = pedidoretorno.entregador;
          },
          erro => {
            self.mensagem = erro.error;
            self.ativar_spinner = false;
          })

        //var pedidoRetorno = await this.pedidoServico.atualizar(pedidoSelecionado).toPromise()
        //    pedidosRetorno.push(pedidoRetorno.numeroPedidoDia);// = `${pedidoRetorno.numeroPedidoDia} será entregue por ${pedidoRetorno.entregador.nome}(${pedidoRetorno.entregador.apelido})`;
        //    entregador = pedidoRetorno.entregador;
      });
    }, 500);

    // chamar servico para atribuir entregador ao pedido


    //if (entregador)
    //  this.mensagem = `${msg} ${pedidosRetorno.join(', ')} será(ão) entregue(s) por ${entregador.nome}(${entregador.apelido})`;

    self.ativar_spinner = false;

    $(() => {
      $('#modalCriarRoteiro').modal('hide');
      if (self.consultaEspecifica)
        self.consultaPedidos();
      else
        self.listaComandasAbertas();

      window.setTimeout(function () { self.mensagem = "" }, 5000);
    })

  }


  abrirFinalizarPedido(pedido: Pedido) {

    this.pedidoSelecionado = pedido;
    this.formaPagamento = Number(pedido.formaPagamento).toString();
    this.valorPedido = pedido.valorTotal;

    if (pedido.formaPagamento == 4) {
      this.focoTrocoPara();
    }

    $(() => {
      $('#modalFinalizarEntrega').modal('show');
    });
  }

  finalizarPedido(pedido: Pedido) {
    var self = this;
    var status = StatusEnum.Entregue;

    pedido.formaPagamento = parseInt(self.formaPagamento);

    if (pedido.formaPagamento == 4) {
      pedido.valorTroco = this.trocoPara - pedido.valorTotal;
    }

    if (confirm(`Deseja realmente finalizar o Pedido ${pedido.numeroPedidoDia}?`)) {

      if (pedido.valorTotal != (pedido.valorPago + pedido.valorCortesia)) {
        if (!confirm('O valor pago informado está diferente do valor cobrado, deseja continuar?')) {
          return;
        }
      }

      pedido.status = status;

      // chamar servico para alterar os status de acordo com o status finalizador
      this.pedidoServico.atualizar(pedido).subscribe(
        pedidoRetorno => {
          self.mensagem = `O pedido ${pedidoRetorno.numeroPedidoDia} foi atualizado para: ${self.StatusEnum[status]}`;

          if (confirm('Deseja imprimir o pedido finalizado?')) {
            self.imprimirPedido(pedido);
          }

          if (self.consultaEspecifica)
            self.consultaPedidos();
          else
            self.listaComandasAbertas();

          self.pedidoSelecionado = new Pedido();

          $(() => {
            $('#modalFinalizarEntrega').modal('hide');
          });
        },
        erro => {
          self.mensagem = erro.error;
          $(() => {
            $('#modalFinalizarEntrega').modal('hide');
          });
        }
      );

    }

  }


  listaComandasAbertas() {
    var self = this;
    this.ativar_spinner = true;
    this.consultaEspecifica = false;

    if (!self.filtroHoje) {
      if (!confirm(`Deseja realmente realizar a pesquisa de TODOS os pedidos do sistema? \nIsso pode gerar lentidão na tela`)) {

        self.filtroHoje = true;
        $(() => {
          $("#cbTodosPedidos").prop('checked', true);
        });
        this.ativar_spinner = false;
        return;
      }
    }

    this.pedidoServico.consultarPedidosAbertos(self.filtroHoje, self.filtroAbertos, self.filtroIfood).subscribe(
      listaPedidosAbertos => {
        self.listaPedidos = listaPedidosAbertos;
        self.ativar_spinner = false;
        self.updateTooltip();
        self.updateSelecionaTodos();
      },
      erro => {
        self.mensagem = erro.error;
        self.ativar_spinner = false;
      }
    );

  }

  async imprimirPedido(pedido: Pedido) {
    while (this.pedidoServico.imprimindo) {
      await this.sleep(1000);
    }

    this.pedidoServico.imprimindo = true;

    this.pedidoServico.montarImpressaoPedido(pedido).subscribe(
      impressao => {
        this.pedidoServico.imprimirViaServico(impressao).subscribe(
          retorno => {
            console.log(retorno);
            this.pedidoServico.imprimindo = false;
          },
          error => {
            this.mensagem = error;
            this.pedidoServico.imprimindo = false;
          }
        )
      },
      error => {
        this.mensagem = error;
      }
    )
  }

  sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  informarValorPago(e) {
    if (e > this.pedidoSelecionado.valorTotal) {
      this.pedidoSelecionado.valorCaixinha = this.pedidoSelecionado.valorPago - this.pedidoSelecionado.valorTotal;
    }
    else {
      this.pedidoSelecionado.valorCaixinha = 0;
    }
  }

  abrirCancelarPedido(pedido: Pedido) {
    if (!this.permiteCancelarPedido) {
      var perfil = this.usuarioServico.usuario.perfil;

      if (perfil != 1 && perfil != 5) {
        alert("Você não tem permissão para cancelar pedido!");
        return;
      }
    }

    var self = this;
    this.pedidoSelecionado = pedido;

    $(() => {
      $('#modalCancelarPedido').modal('show');

      $('#modalCancelarPedido')
        .off('shown.bs.modal')
        .on('shown.bs.modal', function () {
          $('#txtMotivo').find('input').focus();
        });


      $('#modalCancelarPedido')
        .off('hidden.bs.modal')
        .on('hidden.bs.modal', function () {
          self.pedidoSelecionado = new Pedido();
          self.entregadorSelecionadoId = 0;
        });
    });

  }

  cancelarPedido(pedido: Pedido) {
    var self = this;
    var status = StatusEnum.Cancelado;

    if (confirm(`Deseja realmente cancelar o Pedido ${pedido.numeroPedidoDia}?`)) {
      pedido.status = status;
      this.motivoCancelamento.pedidoId = pedido.id;
      this.motivoCancelamento.id = 0;

      this.pedidoServico.inserirMotivoCancelamento(this.motivoCancelamento).subscribe(
        motivoRetorno => {
          pedido.motivoCancelamento = this.motivoCancelamento;
          this.pedidoServico.atualizar(pedido).subscribe(
            pedidoRetorno => {
              self.mensagem = `O pedido ${pedidoRetorno.numeroPedidoDia} foi atualizado para: ${self.StatusEnum[status]}`;

              self.atualizarStatusIfood(pedidoRetorno, this.motivoCancelamento.descricao);

              if (self.consultaEspecifica)
                self.consultaPedidos();
              else
                self.listaComandasAbertas();

              self.pedidoSelecionado = new Pedido();

              $(() => {
                $('#modalCancelarPedido').modal('hide');
              });
            },
            erro => {
              self.mensagem = erro.error;
              $(() => {
                $('#modalCancelarPedido').modal('hide');
              });
            }
          );
        },
        erro => {
          console.log(erro);
        }
      );

      // chamar servico para alterar os status de acordo com o status finalizador
    }
  }

  public pagamentoCartao() {
    this.trocoPara = 0;
    this.setFormaPagamento();
  }

  public focoTrocoPara() {

    var self = this;
    var to = window.setTimeout(function () {
      $('#txtTrocoPara').focus();

      var valor = Math.ceil(self.valorPedido);
      while (valor % 10 > 1) {
        valor++;
      }
      self.trocoPara = valor;
      $('#txtTrocoPara').val(valor);
      clearInterval(to);
    }, 500);

    this.setFormaPagamento();

  }

  private setFormaPagamento() {
    this.pedidoSelecionado.formaPagamento = parseInt(this.formaPagamento);
  }

  liberarParaRetirada(pedido: Pedido) {
    var self = this;
    this.ativar_spinner = true;
    this.pedidoSelecionado = pedido;

    this.pedidoSelecionado.status = StatusEnum.Retirar;

    // chamar servico para atribuir entregador ao pedido
    this.pedidoServico.atualizar(this.pedidoSelecionado).subscribe(
      pedidoRetorno => {
        self.mensagem = `O pedido ${pedidoRetorno.numeroPedidoDia} está pronto para retirada`;

        self.atualizarStatusIfood(pedidoRetorno, null);

        if (self.consultaEspecifica)
          self.consultaPedidos();
        else
          self.listaComandasAbertas();

        self.ativar_spinner = false;
      },
      erro => {
        self.mensagem = erro.error;
        self.ativar_spinner = false;
      }

    )

  }

  atualizarStatusIfood(pedido: Pedido, motivoCancelamento: string) {
    this.ativar_spinner = true;

    if (pedido.origemPedido == 2) {
      var registroPedidoIfood = new RegistroPedidoIfood();

      registroPedidoIfood.orderId = pedido.ifoodId;
      registroPedidoIfood.statusPedido = pedido.status;
      registroPedidoIfood.motivoCancelamento = motivoCancelamento;

      this.pedidoServico.obterServidorIfood().subscribe(
        urlRetorno => {

          this.pedidoServico.atualizarStatusIfood(registroPedidoIfood, urlRetorno).subscribe();
          this.ativar_spinner = false;
        },
        erro => {
          this.mensagem = erro.error;
          this.ativar_spinner = false;
        }

      )


    }
  }

  obterStatusLojaIfood() {
    this.ativar_spinner = true;

    this.pedidoServico.obterStatusLojaIfood().subscribe(
      lojaRetorno => {

        if (lojaRetorno.message != null) {
          this.statusLojaIfoodMsg = lojaRetorno.message.description == null ? lojaRetorno.message.subtitle : lojaRetorno.message.description;
        }
        else {
          this.statusLojaIfoodMsg = "";
        }

        $(() => {
          $('#spanIfoodStatus').attr('data-original-title', this.statusLojaIfoodMsg);
          $('#spanIfoodStatus').attr('title', null);
        });

        switch (lojaRetorno.state) {
          case "OK": {
            this.statusLojaIfood = "Aberto";
            this.statusLojaIfoodCss = "statusIfoodAberto";
            break;
          }
          case "WARNING": {
            this.statusLojaIfood = "Aberto com restrição";
            this.statusLojaIfoodCss = "statusIfoodRestricao";
            break;
          }
          case "CLOSED": {
            this.statusLojaIfood = "Fechada";
            this.statusLojaIfoodCss = "statusIfoodFechada";
            break;
          }
          case "ERROR": {
            this.statusLojaIfood = "Fechada com erro";
            this.statusLojaIfoodCss = "statusIfoodErro";
            break;
          }
          default: {
            this.statusLojaIfood = "Fechada";
          }
        }
        this.ativar_spinner = false;
      },
      erro => {
        this.ativar_spinner = false;
      }
    )
  }

  origemPedido(origem, balcao) {
    var retorno = '';
    switch (origem) {
      case 1: {
        if (balcao)
          retorno = "Balcão";
        else
          retorno = "Entrega";
        break;
      }
      case 2: {
        retorno = "Ifood";
        break;
      }
      case 3: {
        retorno = "WhatsApp";
        break;
      }
    }

    return retorno;
  }

  public sortNumberColumn(coluna) {
    this.ascNumberSort = !this.ascNumberSort;

    switch (coluna) {
      case 1:
        if (this.ascNumberSort) {
          this.listaPedidos.sort((a, b) => a.numeroPedidoDia - b.numeroPedidoDia); // For ascending sort
        }
        else {
          this.listaPedidos.sort((a, b) => b.numeroPedidoDia - a.numeroPedidoDia); // For descending sort
        }
        break;
      case 2:
        if (this.ascNumberSort) {
          this.listaPedidos.sort((a, b) => (a.cliente.nome > b.cliente.nome ? -1 : 1)); // For ascending sort
        }
        else {
          this.listaPedidos.sort((a, b) => (b.cliente.nome > a.cliente.nome ? -1 : 1)); // For descending sort
        }
        break;
      case 3:
        if (this.ascNumberSort) {
          this.listaPedidos.sort((a, b) => (this.origemPedido(a.origemPedido, a.balcao) > this.origemPedido(b.origemPedido, b.balcao) ? -1 : 1)); // For ascending sort
        }
        else {
          this.listaPedidos.sort((a, b) => (this.origemPedido(b.origemPedido, b.balcao) > this.origemPedido(a.origemPedido, a.balcao) ? -1 : 1)); // For descending sort
        }
        break;
      case 4:
        if (this.ascNumberSort) {
          this.listaPedidos.sort((a, b) => (a.dataPedido > b.dataPedido ? -1 : 1)); // For ascending sort
        }
        else {
          this.listaPedidos.sort((a, b) => (b.dataPedido > a.dataPedido ? -1 : 1)); // For descending sort
        }
        break;
      case 5:
        if (this.ascNumberSort) {
          this.listaPedidos.sort((a, b) => (a.valorTotal > b.valorTotal ? -1 : 1)); // For ascending sort
        }
        else {
          this.listaPedidos.sort((a, b) => (b.valorTotal > a.valorTotal ? -1 : 1)); // For descending sort
        }
        break;
      case 6:
        if (this.ascNumberSort) {
          this.listaPedidos.sort((a, b) => (this.StatusEnum[a.status] > this.StatusEnum[b.status] ? -1 : 1)); // For ascending sort
        }
        else {
          this.listaPedidos.sort((a, b) => (this.StatusEnum[b.status] > this.StatusEnum[a.status] ? -1 : 1)); // For descending sort
        }
        break;
    }

  }

  voltarPedido() {
    if (this.indicePedidoSelecionado > 0) {
      var indiceAtual = this.indicePedidoSelecionado;
      var qtdeRegistrosParaSomar = Number(this.contador) * (Number(this.pag) - 1);
      this.indicePedidoSelecionado = this.indicePedidoSelecionado + qtdeRegistrosParaSomar;
      this.indicePedidoSelecionado--;
      indiceAtual--;
      this.pedidoSelecionado = this.listaPedidos[this.indicePedidoSelecionado];
      this.indicePedidoSelecionado = indiceAtual;
    }
  }

  avancarPedido() {
    var indiceAtual = this.indicePedidoSelecionado;
    var qtdeRegistrosParaSomar = Number(this.contador) * (Number(this.pag) - 1);
    this.indicePedidoSelecionado = this.indicePedidoSelecionado + qtdeRegistrosParaSomar;
    this.indicePedidoSelecionado++;
    indiceAtual++;
    this.pedidoSelecionado = this.listaPedidos[this.indicePedidoSelecionado];
    this.indicePedidoSelecionado = indiceAtual;
  }

  filtroTelaCheia(event) {
    if (event.checked) {
      this.classeListaPedidos = "";
    }
    else {
      this.classeListaPedidos = "container";
    }
  }

  public usuarioAutenticado(): Usuario {
    return this.usuarioServico.usuario;
  }
}
