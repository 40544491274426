import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router";
import { Chart } from "chart.js"
import { DadosRelatorio } from "../../model/dadosRelatorio";
import { RelatorioServico } from "../../servicos/relatorio/relatorio.servico";
import { loadCldr, L10n } from "@syncfusion/ej2-base";

//variavel para utilizar jquery
declare var $: any;
declare var require: any;

loadCldr(
  require("cldr-data/main/pt/numbers.json"),
  require("cldr-data/main/pt/ca-gregorian.json"),
  require("cldr-data/supplemental/numberingSystems.json"),
  require("cldr-data/main/pt/timeZoneNames.json"),
  require('cldr-data/supplemental/weekdata.json') // To load the culture based first day of week
);

@Component({
  selector: "app-novos-clientes-relatorio",
  templateUrl: "./novos-clientes.relatorio.component.html",
  styleUrls: ["./novos-clientes.relatorio.component.css"]
})
export class NovosClientesRelatorioComponent implements OnInit {
  constructor(private router: Router, private relatorioServico: RelatorioServico, public datepipe: DatePipe) {

  }

  public chart: any;
  public ativar_spinner: boolean = false;
  public anos: number[];
  public anoSelecionado: number = 2021;

  ngOnInit(): void {
    $(() => {
      $(document).ready(function () {

      })
      this.anos = this.gerarAnos(2021);
    });
  }

  createChart(relatorio) {
    this.chart = new Chart("MyChart", {
      type: 'bar',

      data: relatorio,
      options: {
        aspectRatio: 2.5
      }
    });
    this.chart.update();
  }

  gerarGrafico() {
    this.ativar_spinner = true;

    this.relatorioServico.obterNovosClientesMes(this.anoSelecionado)
      .subscribe(
        relatorio => {
          if (this.chart)
            this.chart.destroy();

          this.createChart(relatorio);
          this.ativar_spinner = false;
        },
        err => {
          console.log(err.error);
          this.ativar_spinner = false;
        }
      )
  }

  gerarAnos(anoInicio) {
    var anoAtual = new Date().getFullYear(), years = [];
    anoInicio = anoInicio || 1980;
    while (anoInicio <= anoAtual) {
      years.push(anoInicio++);
    }
    return years;
  }
}
